import React, { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { StyledU, MenuItemWrappper } from '../../DropDown/style';
import { ImageUrl } from '../../../utils/constants';
import { Layout, MenuItem, CheckMarkContainer } from './style';
import { withTranslation } from 'react-i18next';

class StyledDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      title: props.title,
    };
    this.divRef = createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside(event) {
    if (this.divRef.current && !this.divRef.current.contains(event.target)) {
      this.setState({ open: false });
      this.props.hideDropDown();
    }
  }

  showMenuItems = () => {
    this.setState((prev) => ({
      open: !prev.open
    }));
    this.props.hideDropDown();
  };

  componentDidMount() {
    const stringArray = this.props.history.location.pathname.split('/');
    let dropdownTitle = stringArray[stringArray.length - 1];
    this.setState({
      title: dropdownTitle
    });
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.history.location !== this.props.history.location) {
      const stringArray = this.props.history.location.pathname.split('/');
      let dropdownTitle = stringArray[stringArray.length - 1];
      this.setState({
        title: dropdownTitle
      });
    }
  }

  selectMenuItem = (item) => {
    const { history, onSelect, updateCategoryName } = this.props;
    this.setState({
      title: item.id,
    });
    history.push(`/events/ondemand/${item.id}`);
    onSelect(item.id);
    updateCategoryName(item.name);
    this.showMenuItems();
  };

  render() {
    const { title, open } = this.state;
    const { isCompanyDashboard, isOpen, MenuItems } = this.props;
    return (
      isOpen && open && (
        <Layout width={"230px"} ref={this.divRef} isOpen={isOpen} isCompanyDashboard={isCompanyDashboard}>
          <div ref={(node) => this.wrapperRef = node}>
            <div>
              <StyledU>
                <MenuItemWrappper padding>
                  {
                    MenuItems.map((item, index) => (
                      <MenuItem
                        key={item.id}  // Ensure unique key
                        onClick={() => this.selectMenuItem(item)}
                        isActive={item.name}
                        color={item.id === title}
                        border={(index + 1) === MenuItems.length}
                        style={{ color: "#005c87" }}
                      >
                        <CheckMarkContainer>
                          <img src={`${ImageUrl}/${item.grey_icon}`} alt={item.name} />
                        </CheckMarkContainer>
                        {this.props.t(item.name)}
                      </MenuItem>
                    ))
                  }
                </MenuItemWrappper>
              </StyledU>
            </div>
          </div>
        </Layout>
      )
    );
  }
}

StyledDropDown.propTypes = {
  title: PropTypes.string.isRequired,
  MenuItems: PropTypes.array.isRequired,
  isCompanyDashboard: PropTypes.bool,
  isAdventurePage: PropTypes.bool,
  eventKey: PropTypes.number,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  onSelect: PropTypes.func,
  isReports: PropTypes.bool,
  isOpen: PropTypes.bool,
  hideDropDown: PropTypes.func,
  updateCategoryName: PropTypes.func,
  t: PropTypes.func,
};

export default withRouter(withTranslation()(StyledDropDown));